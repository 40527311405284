
import {ITTIModelBase} from "./ITTIModel";

export default {

  props:{
    imodel:{
      type: ITTIModelBase,
      default () {
        return this.injectModel
      }
    }
  },

  inject: {
    injectModel: {
      from: 'imodel',
      default: null,
    },
  },

  provide: function () {
    return {
      imodel: this.imodel,
    };
  },
}