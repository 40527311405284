<template>
	<router-link v-if="routerLink" :to="routerLink" :target="targetBlank ? '_blank' : '_self'">
		<v-btn text link small>{{ label }}</v-btn>
	</router-link>
</template>

<script>
import {RolesEnum} from "@/ittijs/Authorization";

export default {
	inject: [
		'authorization',
	],
	props: {
		utility: {},
		session: {},
		station: {},
		imot: {},
		targetBlank: Boolean,
		label: {
			type: String,
			default: 'Детайли',
		}
	},
	computed: {
		routerLink(){
			let routeName;
			if (this.authorization.hasRole(RolesEnum.OTCHETI_FULL)) {
				routeName = 'account';
			}
			else if (this.authorization.hasRole(RolesEnum.FRONTDESK)) {
				routeName = 'frontdesk';
			}
			else {
				return null;
			}
			return {
				name: routeName,
				params: {
					utility: this.utility,
					session: this.session,
					station: this.station,
					imot: this.imot,
				},
			}
		},
	},
}
</script>