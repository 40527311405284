<template>
	<v-container fluid>
		<please-select-utility>

			<v-row dense>
				<v-col>
					<v-toolbar class="headings" dense rounded>
						<div>Аб. Станция:</div>
						<div>
							<station-select-all
								v-model="station"
								clearable solo hide-details dense flat
								class="flex-grow-1 ml-4"
							/>
						</div>
					</v-toolbar>
				</v-col>
			</v-row>

			<v-row>
				<v-col>
					<list
						ref="dataTable"
						hide-edit hide-select hide-delete
						:imodel="imodel"
						:params="{utility,station}"
						:headers="headers.join(',')"
						item-key="n_device_data.id"
						hidden-cols="n_device_data.id,_id.imot_id,_sd.station_id"
					>
						<template #right-actions="{item}">
							<imot-details-link
								target-blank
								:utility="utility"
								:session="item['ndd.session_id']"
								:station="item['nst.id']"
								:imot="item['ni.id']"
							/>
						</template>
					</list>
				</v-col>
			</v-row>

		</please-select-utility>
	</v-container>
</template>

<script>
import PleaseSelectUtility from "@/ittijs/components/PleaseSelectUtility.vue";
import StationSelectAll from "@/views/stations/StationSelectAll.vue";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import RouteSyncMixin from "@/ittijs/RouteSyncMixin";
import ImotDetailsLink from "@/ittijs/components/ImotDetailsLink.vue";
import List from "@/ittijs/List.vue";
import ITTIModel from "@/ittijs/ITTIModel";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";

class Model extends ITTIModel {
	constructor() {
		super('ImotiZaUP');
	}
}

const imodel = ITTIModelProvider.getModel(Model);

export default {
	components: {
		List,
		ImotDetailsLink,
		StationSelectAll,
		PleaseSelectUtility,
	},
	mixins: [
		SelectedUtilityMixin,
		RouteSyncMixin({
			utility: 'selectedUtility',
		}),
	],
	props: [
		'utility',
	],
	data(){
		return {
			station: null,
			imodel,
			headers: [
				'nst.station_N',
				'ni.imot_N',
				'ni.address',
				'ni.location',
				'ni.client_N',
			],
		}
	},
	methods: {
	},
}
</script>